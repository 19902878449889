var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full_overlay" }, [
    _c("div", { staticClass: "lang_wrap" }, [
      _c("div", { staticClass: "lang_header" }),
      _c(
        "div",
        { attrs: { id: "lang_form" } },
        [
          _vm._l(_vm.availableLanguages, function (lang) {
            return _c("label", { key: lang.code, staticClass: "check-radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.langCode,
                    expression: "langCode",
                  },
                ],
                attrs: { type: "radio", name: "lang" },
                domProps: {
                  value: lang.code,
                  checked: _vm._q(_vm.langCode, lang.code),
                },
                on: {
                  change: function ($event) {
                    _vm.langCode = lang.code
                  },
                },
              }),
              _c("span", { staticClass: "img-btn img-hover-btn" }),
              _vm._v("\n        " + _vm._s(lang.label) + "\n      "),
            ])
          }),
          _c("button", {
            ref: "submitLang",
            staticClass: "img-btn img-hover-btn",
            attrs: { id: "submitLang", disabled: !_vm.langCode },
            on: { click: _vm.submitLang },
          }),
          _c("div", { staticClass: "clr" }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }