<template>
  <div class="full_overlay">
    <div class="lang_wrap">
      <div class="lang_header" />
      <div id="lang_form">
        <label
          v-for="lang in availableLanguages"
          :key="lang.code"
          class="check-radio"
        >
          <input
            v-model="langCode"
            type="radio"
            name="lang"
            :value="lang.code"
          >
          <span class="img-btn img-hover-btn" />
          {{ lang.label }}
        </label>

        <button
          id="submitLang"
          ref="submitLang"
          class="img-btn img-hover-btn"
          :disabled="!langCode"
          @click="submitLang"
        />
        <div class="clr" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LangSelect',
  props: {
    skillsInvited: {
      type: Array
    }
  },
  data() {
    return {
      langCode: 'en'
    }
  },
  computed: {
    isPiraeus() {
      return this.$store.getters.isPiraeus
    },

    isInvitedInNewSkills() {
      return this.skillsInvited.filter(e => e.softSkill.name === 'ENTREPRENEURSHIP' || e.softSkill.name === 'PEOPLE_SUPPORT' || e.softSkill.name === 'PROJECT_MANAGEMENT').length > 0
    },
    availableLanguages() {
      const languages = require('@/config/AvailableLanguages').default

      return languages.filter(lang => {
        if (this.isPiraeus && lang.piraeusIncluded !== true) return false
        if (this.isInvitedInNewSkills && lang.hasNewSkills !== true) return false
        return lang.active === true
      })
    }
  },
  methods: {
    submitLang() {
      this.startBtnLoading(this.$refs.submitLang)

      this.$store.dispatch('setLanguage', {
        playerResultId: this.$store.getters.playerResultId,
        langKey: this.langCode
      })
        .then(res => {
          this.$i18n.locale = this.langCode
          document.querySelector('html').setAttribute('lang', this.langCode)
          this.$router.replace('/game')
          this.sendGameProgressToGA('lang_select', this.langCode, 0)
        })
    }
  }
}
</script>
