export default [
  {
    code: 'br',
    label: 'Português brasileiro',
    active: true,
    hasNewSkills: false
  },
  {
    code: 'en',
    label: 'English - UK',
    active: true,
    piraeusIncluded: true,
    hasNewSkills: true
  },
  {
    code: 'us',
    label: 'English - US',
    active: false,
    hasNewSkills: false
  },
  {
    code: 'el',
    label: 'Greek - Ελληνικά',
    active: true,
    piraeusIncluded: true,
    hasNewSkills: true
  },
  {
    code: 'it',
    label: 'Italian - Italiano',
    active: true,
    hasNewSkills: false
  },
  {
    code: 'es',
    label: 'Spanish - Español',
    active: true,
    hasNewSkills: false
  },
  {
    code: 'de',
    label: 'German - Deutsch',
    active: true,
    hasNewSkills: false
  },
  {
    code: 'tr',
    label: 'Turkish - Türkçe',
    active: true,
    hasNewSkills: true
  },
  {
    code: 'ru',
    label: 'Russian - русский',
    active: true,
    hasNewSkills: false
  },
  {
    code: 'ko',
    label: 'Korean - 한국인',
    active: true,
    hasNewSkills: true
  },
  {
    code: 'sl',
    label: 'Slovenian - slovenski',
    active: true,
    hasNewSkills: true
  }
]
